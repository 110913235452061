import { init } from "@sentry/gatsby";

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";
init({
  dsn: "https://9cb7657a010b4e77b7d6423172884937@o132674.ingest.sentry.io/1807370",
  environment: activeEnv,
  debug: process.env.ENABLE_SENTRY_DEBUG,
  release: process.env.GATSBY_APP_VERSION || process.env.GITHUB_SHA || process.env.COMMIT_REF,
  attachStacktrace: process.env.ENABLE_SENTRY_DEBUG,
  integrations: [],
  beforeSend(event) {
    if (event && event.exception && event.exception.values) {
      const values = event.exception.values;
      if (values.length > 0) {
        const value = values[values.length - 1];
        if (value.mechanism) {
          if (value.mechanism.handled) {
            // we don't send handled errors to Sentry
            // because we are already catching them
            // so we don't need to take any action
            return;
          }
        }
      }
    }
    return event;
  },
});
